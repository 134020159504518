
export const languageType=()=>{
  let languageCokieValue;
  if (typeof window != 'undefined') {
    languageCokieValue =
    document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'fr';
  } else {
    languageCokieValue = 'fr';
  }
  return languageCokieValue
}