import React, { useState } from 'react';
import css from './Bancontact.module.css';
import { PrimaryButton } from '../Button/Button';
import { createStripePaymentMethod } from '../../util/api';
import { useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import bancontact from '../../assets/bancontact-svgrepo-com.svg';
const Bancontact = props => {
  const { orderParams } = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const currentUser = useSelector(state => state.user.currentUser);
  const email = currentUser?.attributes?.email;
  const profile = currentUser?.attributes?.profile;
  const fullName = `${profile?.firstName} ${profile?.lastName}`;
  return (
    <div>
      {' '}
      {error ? (
        <p>
          {' '}
          <span className={css.orderError}>{error}</span>
        </p>
      ) : null}
      <PrimaryButton
        className={css.checkoutButton}
        type="button"
        inProgress={loading}
        spinnerClassName={css.spinner}
        onClick={async () => {
          try {
            setLoading(true);
            const secret = await createStripePaymentMethod({
              orderParams,
              paymentMethod: 'bancontact',
            });
            const clientSecret = secret?.clientSecret;
            const txId = secret?.txId;
            const { error } = await stripe.confirmBancontactPayment(clientSecret, {
              payment_method: {
                billing_details: {
                  name: fullName,
                  email: email,
                },
              },
              return_url: `${window.location.href}/${txId}/payment/success`,
            });

            if (error) {
              setLoading(false);
              console.log(error);
              setError(error.message);
            } else {
              setLoading(false);
            }
          } catch (e) {
            console.log('e', e);
          }
        }}
      >
        <p className={css.text}>
          {intl.formatMessage({ id: 'Bancontact.Bancontact.payWithBancontact' })}
        </p>

        <img src={bancontact} className={css.logoImage} />
      </PrimaryButton>
    </div>
  );
};

export default Bancontact;
