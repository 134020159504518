import React from 'react';
import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';
import { FieldLocationAutocompleteInput, LocationAutocompleteInput } from '../../components';
import css from './SearchBar.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
const identity = v => v;

function LocationSearch({ values, routeConfiguration }) {
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const history = useHistory();

        const searchOnChange = value => {
          onChange(value);
          const { search, selectedPlace } = value || {};
          const { origin, bounds } = selectedPlace || {};
          const originMaybe = origin ? { origin } : {};
          const { keyword, category } = values;
          if (!bounds) return;
          const searchParams = {
            ...originMaybe,
            address: search,
            bounds,
            keywords: keyword,
            pub_listingType: category,
            // : newBounds,
            pub_listingType: category,
          };

          history.push(
            createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams)
          );
          handleChange(value);
        };
        const intl = useIntl();
        const searchInput = { ...restInput, onChange: searchOnChange };
        return (
          <FieldLocationAutocompleteInput
            rootClassName={css.locationField}
            inputClassName={css.keywordInputRoot}
            iconClassName={css.fieldTextInput}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            placeholder={intl.formatMessage({ id: 'SearchBar.LocationSearch.localtionLabel' })}
            useDefaultPredictions={false}
            format={identity}
            input={searchInput}
            valueFromForm={values.location}
            isLandingPage={true}
            hideIcon={true}
          />
        );
      }}
    />
  );
}

export default LocationSearch;
