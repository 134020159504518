import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { useSelector } from 'react-redux';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const isParent = currentUser?.attributes?.profile?.publicData?.userType === 'parent';
  const isPerinatalCenter =
    currentUser?.attributes?.profile?.publicData?.userType === 'perinatalCenter';
  const isProfessional = currentUser?.attributes?.profile?.publicData?.userType === 'professional';
  const tabs = isParent
    ? [
        // {
        //   text: <FormattedMessage id="UserNav.yourListings" />,
        //   selected: currentPage === 'ManageListingsPage',
        //   linkProps: {
        //     name: 'ManageListingsPage',
        //   },
        // },
        {
          text: <FormattedMessage id="UserNav.profileSettings" />,
          selected: currentPage === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ]
    : isProfessional
    ? [
        {
          text: <FormattedMessage id="UserNav.UserNav.spaceToRentLabel" />,
          selected: currentPage === 'SpacesToRentPage',
          linkProps: {
            name: 'SpacesToRentPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.yourListings" />,
          selected: currentPage === 'ManageListingsPage',
          linkProps: {
            name: 'ManageListingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.favoriteListings" />,
          selected: currentPage === 'FavoriteListingsPage',
          linkProps: {
            name: 'FavoriteListingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.profileSettings" />,
          selected: currentPage === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ]
    : [
        {
          text: <FormattedMessage id="UserNav.yourListings" />,
          selected: currentPage === 'ManageListingsPage',
          linkProps: {
            name: 'ManageListingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.favoriteListings" />,
          selected: currentPage === 'FavoriteListingsPage',
          linkProps: {
            name: 'FavoriteListingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.profileSettings" />,
          selected: currentPage === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
