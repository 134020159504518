import React from 'react';
import { MdVerified } from 'react-icons/md';
import css from './Verified.module.css';
const Verified = ({ name }) => {
  return (
    <div>
      <span>
        {name}
        <span>
          {' '}
          <MdVerified className={css.verified} />
        </span>
      </span>
    </div>
  );
};

export default Verified;
