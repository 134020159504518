import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
// import { getAssetName } from '../../util/';
export const ASSET_NAME = 'privacy-policy';
export const ENGLISH_ASSET_NAME = 'privacy-policy-english'
import { getAssetName } from '../../util/getAssetName';

export const loadData = (params, search, config, lang) => dispatch => {
  const pageAsset = { privacyPolicy: `content/pages/${getAssetName(lang,ASSET_NAME,ENGLISH_ASSET_NAME)}.json` };

  return dispatch(fetchPageAssets(pageAsset, true));
};
// const pageAsset = { privacyPolicy: `content/pages/${getAssetName(ASSET_NAME, FRENCH_ASSET_NAME)}.json` };
