import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
// import { getAssetName } from '../../util/';
export const ASSET_NAME = 'landing-page';
export const ENGLISH_ASSET_NAME = 'landing-page-english'
import { getAssetName } from '../../util/getAssetName';

export const loadData = (params, search,config, lang) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${getAssetName(lang,ASSET_NAME,ENGLISH_ASSET_NAME)}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
