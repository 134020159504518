import React from 'react';
import css from './AffiliationButton.module.css';
import { PrimaryButton } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { createAffiliation } from '../../ducks/user.duck';
const AffiliationButton = ({ label }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.user);
  const { currentUser, affiliationInProgress, affiliationError } = state;
  if (!currentUser) {
    return null; // Hide the button if the user is not logged in.
  }
  return (
    <div>
      {affiliationError ? <p className={css.error}>{affiliationError.message}</p> : null}
      <PrimaryButton
        className={css.affiliationButton}
        inProgress={affiliationInProgress}
        onClick={() => {
          dispatch(createAffiliation()).then(res => {
            const url = res?.url;
            if (url) window.location.assign(url);
          });
        }}
      >
        {label || 'Affiliation'}
      </PrimaryButton>
    </div>
  );
};

export default AffiliationButton;
