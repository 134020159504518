import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';
export const CHARTE_PARENTS_ASSET_NAME = 'charte-parents';
export const CHARTE_PROFESSIONALS_ASSET_NAME = 'charte-prestataires';
export const TOS_ASSET_NAME_EN = 'terms-of-service-english';
export const PRIVACY_POLICY_ASSET_NAME_EN = 'privacy-policy-english';
export const CHARTE_PARENTS_ASSET_NAME_EN = 'charte-parents-english';
export const CHARTE_PROFESSIONALS_ASSET_NAME_EN = 'charte-professionals-english';

export const loadData = (params, search) => dispatch => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
    charteParents: `content/pages/${CHARTE_PARENTS_ASSET_NAME}.json`,
    chartePrestataires: `content/pages/${CHARTE_PROFESSIONALS_ASSET_NAME}.json`,
    termsOfServiceEnglish: `content/pages/${TOS_ASSET_NAME_EN}.json`,
    privacyPolicyEnglish: `content/pages/${PRIVACY_POLICY_ASSET_NAME_EN}.json`,
    charteParentsEnglish: `content/pages/${CHARTE_PARENTS_ASSET_NAME_EN}.json`,
    // chartePrestatairesEnglish: `content/pages/${CHARTE_PROFESSIONALS_ASSET_NAME_EN}.json`,
  };
  return dispatch(fetchPageAssets(pageAsset, true));
};
