import { fetchBookingsFromDb } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
const FETCH_BOOKINGS_REQUEST = 'app/CalenderPage/FETCH_BOOKINGS_REQUEST';
const FETCH_BOOKINGS_SUCCESS = 'app/CalenderPage/FETCH_BOOKINGS_SUCCESS';
const FETCH_BOOKINGS_ERROR = 'app/CalenderPage/FETCH_BOOKINGS_ERROR';
// ================ Reducer ================ //
const initialState = {
  bookings: [],
  fetchBookingsError: null,
  fetchBookingsInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BOOKINGS_REQUEST:
      return { ...state, fetchBookingsInProgress: true, fetchBookingsError: null, bookings: null };
    case FETCH_BOOKINGS_SUCCESS:
      return { ...state, bookings: payload.bookings, fetchBookingsInProgress: false };
    case FETCH_BOOKINGS_ERROR:
      return {
        ...state,
        fetchBookingsError: payload.error,
        fetchBookingsInProgress: false,
        bookings: null,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
const fetchBookingsSuccess = bookings => ({ type: FETCH_BOOKINGS_SUCCESS, payload: { bookings } });
const fetchBookingsError = error => ({ type: FETCH_BOOKINGS_ERROR, payload: { error } });

// ================ Thunks ================ //
export const fetchBookings = () => (dispatch, getState, sdk) => {
  dispatch(fetchBookingsRequest());
  const currentUser = getState().user.currentUser;
  const currentUserId = currentUser?.id?.uuid;
  if (typeof window === 'undefined') return null;
  return fetchBookingsFromDb({ userId: currentUserId })
    .then(response => {
      const bookings = response;
      dispatch(fetchBookingsSuccess(bookings));
    })
    .catch(e => {
      dispatch(fetchBookingsError(storableError(e)));
    });
};
export const loadData = () => dispatch => {
  return Promise.all([dispatch(fetchBookings())])
    .then(res => {
      return res;
    })
    .catch(e => {
      console.log('e', e);
    });
};
