import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldSelect, FieldTextInput, Form, PrimaryButton } from '../../components';
import LocationSearch from './LocationSearch';
import css from './SearchBar.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { LiaSearchSolid } from 'react-icons/lia';
import { useConfiguration } from '../../context/configurationContext';
import { useSelector } from 'react-redux';
import { ROOM_LISTING_TYPE, SERVICE_LISTING } from '../../config/configListing';
import { IoLocationOutline } from 'react-icons/io5';
import { checkMembership } from '../../config/configMembership';

const identity = v => v;

function SearchForm({ onSubmit }) {
  return (
    <FinalForm
      initialValues={{ type: 'location' }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { handleSubmit, values, pristine } = formRenderProps;
        const isPlaceHolder = values.category === undefined || values.category === '';
        console.log(isPlaceHolder);
        const routeConfiguration = useRouteConfiguration();
        const intl = useIntl();
        const state = useSelector(state => state);
        const { currentUser } = state?.user;
        const { attributes } = currentUser || {};
        const { profile } = attributes || {};
        const { publicData, metadata } = profile || {};
        const { userType } = publicData || {};
        // const { membership } = metadata || {};
        const membership = checkMembership(currentUser);
        const isProfessional = userType === 'professional';
        const searchDisabled =
          pristine || !values.location || !values.startDate || !values.endDate || dateError;
        const config = useConfiguration();
        const listingTypes = config?.listing?.listingTypes || [];
        let listingFilterOptions = listingTypes?.map(type => ({
          option: type?.listingType,
          label: type?.label,
        }));
        // .filter(option => option.option !== ROOM_LISTING_TYPE);
        if (!isProfessional) {
          listingFilterOptions = listingFilterOptions?.filter(
            option => option.option !== ROOM_LISTING_TYPE
          );
        }
        if (isProfessional) {
          if (!membership) {
            listingFilterOptions = listingFilterOptions?.filter(
              option => option.option !== ROOM_LISTING_TYPE
            );
          }
        }
        return (
          <Form onSubmit={handleSubmit} className={css.root}>
            <div className={css.locationContainer}>
              <div className={css.iconContainer}>
                <FieldSelect
                  className={css.keywordField}
                  selectClassName={`${
                    isPlaceHolder ? css.keywordInputRootPlaceholder : css.keywordInputRoot
                  }`}
                  id="category"
                  name="category"
                >
                  <option style={{ display: 'none' }} disabled value="">
                    {intl.formatMessage({ id: 'SearchBar.SearchBar.categoryLabel' })}
                  </option>
                  {listingFilterOptions.map(option => (
                    <option key={option.option} value={option.option}>
                      {option.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
              <div className={css.iconContainer}>
                <FieldTextInput
                  className={css.keywordField}
                  inputRootClass={css.keywordInputRoot}
                  id="keyword"
                  name="keyword"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'SearchBar.SearchBar.keywordPlaceholder' })}
                  isLandingPage={true}
                />
                <LiaSearchSolid className={css.locationIcon} />
              </div>
              <div className={css.locationContainer}>
                <div className={css.iconContainer}>
                  <LocationSearch
                    className={css.locationField}
                    id="location"
                    name="location"
                    // className={css.dateField}
                    values={values}
                    routeConfiguration={routeConfiguration}
                  />
                  <IoLocationOutline className={css.locationIcon} />
                </div>
              </div>
              <LiaSearchSolid className={css.mobileIcon} onClick={handleSubmit} />
              <PrimaryButton
                type="submit"
                className={css.searchButton}
                // disabled={searchDisabled}
              >
                <FormattedMessage id="SearchBar.SearchBar.searchButtonLabel" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default SearchForm;
