import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
// import { getAssetName } from '../../util/';
export const ASSET_NAME = 'terms-of-service';
export const ENGLISH_ASSET_NAME = 'terms-of-service-english'
import { getAssetName } from '../../util/getAssetName';

export const loadData = (params, searc, config, lang) => dispatch => {
  const pageAsset = { termsOfService: `content/pages/${getAssetName(lang,ASSET_NAME,ENGLISH_ASSET_NAME)}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
