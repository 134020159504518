import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  fetchBookingsAndWorkshops,
  fetchBookingsFromDb,
  fetchListing,
  fetchUserFromApi,
  transactionLineItems,
  translateListingDescription,
  translateListingTitle,
} from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import {
  findNextBoundary,
  getDefaultTimeZoneOnBrowser,
  getStartOf,
  monthIdString,
  resetToStartOfDay,
} from '../../util/dates';

import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { languageType } from '../../util/languageType';
import moment from 'moment';
import axios from 'axios';
import { DEFAULT_TIMEZONE } from '../../config/configListing';
const { UUID } = sdkTypes;
const rootUrl =
  process.env.REACT_APP_MARKETPLACE_ROOT_URL == 'http://localhost:3000'
    ? 'http://localhost:3500'
    : process.env.REACT_APP_MARKETPLACE_ROOT_URL;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_BOOKING_REQUEST = 'app/ListingPage/FETCH_BOOKING_REQUEST';
export const FETCH_BOOKING_SUCCESS = 'app/ListingPage/FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_ERROR = 'app/ListingPage/FETCH_BOOKING_ERROR';

export const FETCH_EXCEPTION_REQUEST = 'app/ListingPage/FETCH_EXCEPTION_REQUEST';
export const FETCH_EXCEPTION_SUCCESS = 'app/ListingPage/FETCH_EXCEPTION_SUCCESS';
export const FETCH_EXCEPTION_ERROR = 'app/ListingPage/FETCH_EXCEPTION_ERROR';

export const FETCH_USERS_ALL_BOOKINGS_REQUEST = 'app/ListingPage/FETCH_USERS_ALL_BOOKINGS_REQUEST';
export const FETCH_USERS_ALL_BOOKINGS_SUCCESS = 'app/ListingPage/FETCH_USERS_ALL_BOOKINGS_SUCCESS';
export const FETCH_USERS_ALL_BOOKINGS_ERROR = 'app/ListingPage/FETCH_USERS_ALL_BOOKINGS_ERROR';

export const VOUCHER_BOOKINGS_REQUEST = 'app/ListingPage/VOUCHER_BOOKINGS_REQUEST';
export const VOUCHER_BOOKINGS_SUCCESS = 'app/ListingPage/VOUCHER_BOOKINGS_SUCCESS';
export const VOUCHER_BOOKINGS_ERROR = 'app/ListingPage/VOUCHER_BOOKINGS_ERROR';

export const FETCH_USER_BACKEND = 'app/ListingPage/FETCH_USER_BACKEND';
export const FETCH_USER_BACKEND_SUCCESS = 'app/ListingPage/FETCH_USER_BACKEND_SUCCESS';
export const FETCH_USER_BACKEND_ERROR = 'app/ListingPage/FETCH_USER_BACKEND_ERROR';
// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  fetchBookingsLoading: false,
  fetchBookingsError: null,
  bookings: [],
  fetchExceptionLoading: false,
  fetchExceptionError: null,
  exception: null,
  fetchUsersAllBookingsLoading: false,
  fetchUsersAllBookingsError: null,
  usersAllBookings: [],
  applied: false,
  loading: false,
  discountSuccess: null,
  discountFailure: null,
  coupon: '',
  stripeAccountVerified: false,
  fetchingUser: false,
  fetchingUserError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };
    case FETCH_BOOKING_REQUEST:
      return { ...state, fetchBookingsLoading: true, fetchBookingsError: null };
    case FETCH_BOOKING_SUCCESS:
      return { ...state, fetchBookingsLoading: false, bookings: payload };
    case FETCH_BOOKING_ERROR:
      return { ...state, fetchBookingsLoading: false, fetchBookingsError: payload };

    case FETCH_EXCEPTION_REQUEST:
      return { ...state, fetchExceptionLoading: true, fetchExceptionError: null };
    case FETCH_EXCEPTION_SUCCESS:
      return { ...state, fetchExceptionLoading: false, exception: payload };
    case FETCH_EXCEPTION_ERROR:
      return { ...state, fetchExceptionLoading: false, fetchExceptionError: payload };
    case FETCH_USERS_ALL_BOOKINGS_REQUEST:
      return { ...state, fetchUsersAllBookingsLoading: true, fetchUsersAllBookingsError: null };
    case FETCH_USERS_ALL_BOOKINGS_SUCCESS:
      return { ...state, fetchUsersAllBookingsLoading: false, usersAllBookings: payload };
    case FETCH_USERS_ALL_BOOKINGS_ERROR:
      return { ...state, fetchUsersAllBookingsLoading: false, fetchUsersAllBookingsError: payload };

    case VOUCHER_BOOKINGS_REQUEST:
      return {
        ...state,
        applied: true,
        loading: true,
        discountSuccess: null,
        discountFailure: null,
        coupon: '',
      };
    case VOUCHER_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        applied: true,
        discountSuccess: payload.discountSuccess,
        discountFailure: null,
        coupon: payload.coupon,
      };
    case VOUCHER_BOOKINGS_ERROR:
      return {
        ...state,
        loading: false,
        applied: false,
        discountSuccess: null,
        discountFailure: payload,
        coupon: '',
      };
    case FETCH_USER_BACKEND:
      return {
        ...state,
        stripeAccountVerified: false,
        fetchingUser: true,
        fetchingUserError: null,
      };
    case FETCH_USER_BACKEND_SUCCESS:
      return {
        ...state,
        stripeAccountVerified: payload,
        fetchingUser: false,
        fetchingUserError: null,
      };
    case FETCH_USER_BACKEND_ERROR:
      return {
        ...state,
        stripeAccountVerified: false,
        fetchingUser: false,
        fetchingUserError: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //
export const validateVoucher = (voucherNumber, authorId) => (dispatch, getState, sdk) => {
  dispatch({ type: VOUCHER_BOOKINGS_REQUEST });
  return axios
    .post('/voucher-validate', { voucherNumber, authorId })
    .then(response => {
      console.log(response);
      dispatch({ type: VOUCHER_BOOKINGS_SUCCESS, payload: response.data });
    })
    .catch(e => {
      console.log(e);
      dispatch({ type: VOUCHER_BOOKINGS_ERROR, payload: storableError(e) });
    });
};
export const fetchExceptions = params => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_EXCEPTION_REQUEST });
  return sdk.timeslots
    .query(params)
    .then(response => {
      dispatch({ type: FETCH_EXCEPTION_SUCCESS, payload: response.data.data });
    })
    .catch(e => {
      dispatch({ type: FETCH_EXCEPTION_ERROR, payload: storableError(e) });
    });
};
export const fetchBookings = ({ listingId }, config, show = false) => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_BOOKING_REQUEST });

  return sdk.transactions
    .query({
      listingId: listingId.uuid,
      perPage: 100,
      include: ['booking'],
    })
    .then(response => {
      const bookingsIncluded = response?.data?.included || [];
      const bookings = bookingsIncluded?.map(booking => ({
        startDate: booking.attributes.start,
        endDate: booking.attributes.end,
      }));
      dispatch({ type: FETCH_BOOKING_SUCCESS, payload: bookings });
    })
    .catch(e => {
      console.log('e', e);
      dispatch({ type: FETCH_BOOKING_ERROR, payload: e });
    });
};
export const fetchUsersAllBookings = ({ userId }) => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_USERS_ALL_BOOKINGS_REQUEST });
  if (typeof window === 'undefined') return null;
  return fetchBookingsAndWorkshops({ userId })
    .then(response => {
      dispatch({ type: FETCH_USERS_ALL_BOOKINGS_SUCCESS, payload: response });
    })
    .catch(e => {
      console.log('e', e);
      dispatch({ type: FETCH_USERS_ALL_BOOKINGS_ERROR, payload: storableError(e) });
    });
};
// aarsh
const fetchUser = id => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_USER_BACKEND });
  return fetchUserFromApi({ id })
    .then(data => {
      console.log('data', data);
      dispatch({ type: FETCH_USER_BACKEND_SUCCESS, payload: data });
    })
    .catch(e => {
      console.log('e', e);
      dispatch({ type: FETCH_USER_BACKEND_ERROR, payload: storableError(e) });
    });
};
export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  // if (typeof window === 'undefined') return null;
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId?.uuid,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    // 'fields.listing': [
    //   'title',
    //   'geolocation',
    //   'price',
    //   'publicData.listingType',
    //   'publicData.transactionProcessAlias',
    //   'publicData.unitType',
    //   // These help rendering of 'purchase' listings,
    //   // when transitioning from search page to listing page
    //   'publicData.pickupEnabled',
    //   'publicData.shippingEnabled',
    //   'publicData.fr_title',
    //   'publicData.en_title',
    //   'publicData.listingLanguage',
    // ],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',
      // 'publicData.listingLanguage',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);
  // fetchListing({ params })
  return (
    // fetchListing({ params })
    // axios
    //   .post(`${rootUrl}/api/fetch-listing`, { params })
    show
      .then(data => {
        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };
        const { attributes } = data.data.data;

        const { title, description, publicData } = attributes;
        const { workshop = [] } = publicData || {};
        if (workshop.length > 0) {
          const today = new Date();
          const tz = DEFAULT_TIMEZONE;
          // const tz = getDefaultTimeZoneOnBrowser();
          const start = resetToStartOfDay(today, tz, 0);
          const exceptionRange = 90;
          const end = resetToStartOfDay(today, tz, exceptionRange);

          const params = {
            listingId: listingId,
            start,
            end,
          };
          dispatch(fetchExceptions(params));
        }

        if (languageType() === 'en-US') {
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.title = publicData?.en_title ? publicData?.en_title : attributes?.title);
          languageType() === publicData.listingLanguage
            ? null
            : (attributes.description = publicData?.en_description
                ? publicData?.en_description
                : attributes?.description);
          // languageType() === publicData.listingLanguage
          //   ? null
          //   : (publicData.DureeDeLaPrestation = publicData.secondary_DureeDeLaPrestation
          //       ? publicData.secondary_DureeDeLaPrestation
          //       : publicData.DureeDeLaPrestation);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.InformationsSupplementaires = publicData?.secondary_InformationsSupplementaires
                ? publicData?.secondary_InformationsSupplementaires
                : publicData?.InformationsSupplementaires);

          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.PolitiqueAnnulation = publicData?.secondary_PolitiqueAnnulation
                ? publicData?.secondary_PolitiqueAnnulation
                : publicData?.PolitiqueAnnulation);
        } else {
          //if languaugetype ==="fr"//
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.title = publicData?.fr_title ? publicData?.fr_title : attributes?.title);
          languageType() === publicData.listingLanguage
            ? null
            : (attributes.description = publicData?.fr_description
                ? publicData?.fr_description
                : attributes?.description);
          // languageType() === publicData.listingLanguage
          //   ? null
          //   : (publicData.DureeDeLaPrestation = publicData.secondary_DureeDeLaPrestation
          //       ? publicData.secondary_DureeDeLaPrestation
          //       : publicData.DureeDeLaPrestation);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.InformationsSupplementaires = publicData?.secondary_InformationsSupplementaires
                ? publicData?.secondary_InformationsSupplementaires
                : publicData?.InformationsSupplementaires);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.PolitiqueAnnulation = publicData?.secondary_PolitiqueAnnulation
                ? publicData?.secondary_PolitiqueAnnulation
                : publicData?.PolitiqueAnnulation);
        }
        // dispatch(addMarketplaceEntities(data, sanitizeConfig));

        return data;
      })
      .catch(e => {
        dispatch(showListingError(storableError(e)));
      })
  );
};
export const showListingIfAffiation = (listingId, config, isOwn = false) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  if (typeof window === 'undefined') return null;
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId?.uuid,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    // 'fields.listing': [
    //   'title',
    //   'geolocation',
    //   'price',
    //   'publicData.listingType',
    //   'publicData.transactionProcessAlias',
    //   'publicData.unitType',
    //   // These help rendering of 'purchase' listings,
    //   // when transitioning from search page to listing page
    //   'publicData.pickupEnabled',
    //   'publicData.shippingEnabled',
    //   'publicData.fr_title',
    //   'publicData.en_title',
    //   'publicData.listingLanguage',
    // ],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',
      // 'publicData.listingLanguage',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);
  // fetchListing({ params })
  return (
    fetchListing({ params })
      // axios
      //   .post(`${rootUrl}/api/fetch-listing`, { params })

      .then(data => {
        const listingFields = config?.listing?.listingFields;
        const sanitizeConfig = { listingFields };
        const included = data?.data?.included;
        const author = included?.find(item => item.type === 'user');
        const authorId = author?.id?.uuid;
        console.log('authorId', authorId);
        dispatch(fetchUser(authorId));
        // console.log('data>>>>>>', data.data.data);
        const { attributes } = data.data.data;
        const { title, description, publicData } = attributes;
        const { workshop = [] } = publicData || {};
        if (workshop.length > 0) {
          const today = new Date();
          const tz = DEFAULT_TIMEZONE;
          // const tz = getDefaultTimeZoneOnBrowser();
          const start = resetToStartOfDay(today, tz, 0);
          const exceptionRange = 90;
          const end = resetToStartOfDay(today, tz, exceptionRange);

          const params = {
            listingId: listingId,
            start,
            end,
          };
          dispatch(fetchExceptions(params));
        }

        if (languageType() === 'en-US') {
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.title = publicData?.en_title ? publicData?.en_title : attributes?.title);
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.description = publicData?.en_description
                ? publicData?.en_description
                : attributes?.description);
          // languageType() === publicData.listingLanguage
          //   ? null
          //   : (publicData.DureeDeLaPrestation = publicData.secondary_DureeDeLaPrestation
          //       ? publicData.secondary_DureeDeLaPrestation
          //       : publicData.DureeDeLaPrestation);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.InformationsSupplementaires = publicData?.secondary_InformationsSupplementaires
                ? publicData?.secondary_InformationsSupplementaires
                : publicData?.InformationsSupplementaires);

          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.PolitiqueAnnulation = publicData?.secondary_PolitiqueAnnulation
                ? publicData?.secondary_PolitiqueAnnulation
                : publicData?.PolitiqueAnnulation);
        } else {
          //if languaugetype ==="fr"//
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.title = publicData?.fr_title ? publicData?.fr_title : attributes?.title);
          languageType() === publicData?.listingLanguage
            ? null
            : (attributes.description = publicData?.fr_description
                ? publicData?.fr_description
                : attributes?.description);
          // languageType() === publicData.listingLanguage
          //   ? null
          //   : (publicData.DureeDeLaPrestation = publicData.secondary_DureeDeLaPrestation
          //       ? publicData.secondary_DureeDeLaPrestation
          //       : publicData.DureeDeLaPrestation);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.InformationsSupplementaires = publicData?.secondary_InformationsSupplementaires
                ? publicData?.secondary_InformationsSupplementaires
                : publicData?.InformationsSupplementaires);
          languageType() === publicData?.listingLanguage
            ? null
            : (publicData.PolitiqueAnnulation = publicData?.secondary_PolitiqueAnnulation
                ? publicData?.secondary_PolitiqueAnnulation
                : publicData?.PolitiqueAnnulation);
        }
        dispatch(addMarketplaceEntities(data, sanitizeConfig));

        return data;
      })
      .catch(e => {
        dispatch(showListingError(storableError(e)));
      })
  );
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);
  dispatch(fetchTimeSlotsRequest(monthId));
  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
      dispatch(fetchBookings({ listingId }));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;
  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(showListingIfAffiation(listingId, config)),
    dispatch(fetchReviews(listingId)),
    dispatch(fetchBookings({ listingId }, config, true)),
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';

    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }
    return response;
  });
};
