import { googleAnalyticsId } from "./configAnalytics"
import { languageType } from "../util/languageType";
let hasGoogleAnalyticsConsent = false;
const loadCookies = process.env.REACT_APP_LOAD_COOKIES;

console.log(loadCookies)


// console.log('mode ???',mode)
// console.log('is production >>>>??????',mode)
//console.log(`The current mode is set to: ${mode}`);
export const cookieConfig = {
  onChange: ()=> {
   window.location.reload()
  },
  mode: 'opt-out',
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
          enabled: true,
          services: {
              GoogleAnalytics: {
                label: 'GoogleAnalytics.',
                onAccept: () => {
                  
                },
                onReject: () => {
                  window[`ga-disable-${googleAnalyticsId}`] = true;
                },
                cookies: [
                  {
                    name: /^(_ga)/,                  }
                  ]
      
              },
              FacebookPixel: {
                // enabled: true,
                label: 'Meta Pixel.',
                onAccept: () => {
                  
                },
                onReject: () => {
                  
                },
                cookies: [
                  {
                    name: /_fbp/,
                    
                  }
                  ]
      
              },
              Hotjar: {
                // enabled: true,
                label: 'Hotjar.',
                onAccept: () => {
                  
                },
                onReject: () => {
                  
                },
                cookies: [
                  {
                  name: /^(_hjSession)/,
                    
                  }
                  ]
      
              },
              // GoogleTagManager: {
              //   label: 'Google Tag Manager',
              //   onAccept: () => {
              //     
              //   },
              //   onReject: () => {
                  
              //   },
              //   cookies: [
              //     {
              //       name: /_ga/,
                    
              //     }
              //     ]
      
              // },
      }
        
        }
    },

    language: {
        default: `${languageType()==='fr'?'fr':'en'}`,
        translations: {
            'en': {
                consentModal: {
                    title: 'Cookie Management',
                    description: 'At Hapiklan we use cookies to measure our audience, generate statistics and personalise your navigation to give you the best possible experience. Modify your preferences any time by returning to this webste or by visiting the legal information page',
                    acceptAllBtn: 'Accept all',
                    //acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences'
                },
                preferencesModal: {
                    title: 'Manage cookie preferences',
                    acceptAllBtn: 'Accept all',
                    // acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                            title: 'Cookie Usage',
                            description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data.'
                        },
                        {
                            title: 'Strictly Necessary cookies',
                            // description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            
                            cookieTable: {
                              caption: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                              headers: {
                                name: 'Name',
                                domain: 'Service',
                                description: 'Description',
                                expiration: 'Expiration',
                              },
                              body: [
                                {
                                  name: 'st-hosted-token',
                                  domain: 'ShareTribe',
                                  description: 'The Sharetribe cookie is only used to session information',
                                  expiration: '1 Month',
                                },
                                {
                                    name: 'Language',
                                    domain: 'Hapiklan',
                                    description:
                                      'Save Users Language Preference',
                                    expiration: 'Session',
                                  },
                                  {
                                    name: 'hasSubscribed',
                                    domain: 'Hapiklan',
                                    description:
                                      'Save Users Newsletter Preference',
                                    expiration: 'Session',
                                  },
                                  {
                                    name: 'lastModalClosedTime',
                                    domain: 'Hapiklan',
                                    description:
                                      'Save Users Newsletter Preference',
                                    expiration: 'Session',
                                  }, 
                                  {
                                    name: 'cc_cookie',
                                    domain: 'Hapiklan',
                                    description:
                                      'Save Users Cookie Preference',
                                    expiration: '7 months',
                                  },
                                  {
                                    name: '__stripe_mid',
                                    domain: 'Stripe',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.',
                                    expiration: '1 year',
                                  },
                                  {
                                    name: '__stripe_sid',
                                    domain: 'Stripe',
                                    description: 'Set for fraud prevention purposes and helps us assess the risk associated with an attempted transaction.',
                                    expiration: '30 minutes',
                                  },                             
                              ],
                              
                            },
                        },
                        
                        {
                            title: 'Performance and Analytics',
                            // description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                              caption: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                              headers: {
                                name: "Name",
                                domain: "Service",
                                description: "Description",
                                expiration: "Expiration"
                              },
                              body: [
                                {
                                  name: "_ga",
                                  domain: "Google Analytics",
                                  description: "Cookie set by Google Analytics.",
                                  expiration: "2 years"
                                },
                                {
                                  name: "_fbp",
                                  domain: "Meta Pixel",
                                  description: "Cookie set by Meta Pixel.",
                                  expiration: "3 months"
                                },
                                {
                                  name: "_hjSession",
                                  domain: "Hotjar",
                                  description: "Cookie set by Hotjar.",
                                  expiration: "1 year"
                                }
                              ]
                            }
                        }, 
                        {
                          title: 'More information',
                          description: 'For any queries in relation to my policy on cookies and your choices, please contact us.'
                      },                       
                    ]
                }
            },
            'fr': {
              consentModal: {
                  title: 'Gestion des cookies',
                  description: 'Chez Hapiklan, nous utilisons des cookies pour mesurer notre audience, générer des statistiques et personnaliser votre navigation afin de vous offrir la meilleure expérience possible. Modifiez vos préférences à tout moment en revenant sur ce site web ou en visitant la page d\'informations légales.',
                  acceptAllBtn: 'Accepter tout',
                  // acceptNecessaryBtn: 'Rejeter tout',
                  showPreferencesBtn: 'Gérer les préférences individuelles'
              },
              preferencesModal: {
                title: 'Gérer les préférences de cookies',
                acceptAllBtn: 'Accepter tout',
                // acceptNecessaryBtn: 'Rejeter tout',
                savePreferencesBtn: 'Accepter la sélection actuelle',
                closeIconLabel: 'Fermer la fenêtre modale',
                  sections: [
                      {
                          title: 'Utilisation des cookies',
                          description: 'Ces cookies sont essentiels au bon fonctionnement du site web et ne peuvent pas être désactivés.'
                      },
                      {
                          title: 'Cookies strictement nécessaires',
                          description: 'Ces cookies sont essentiels au bon fonctionnement du site web et ne peuvent pas être désactivés.',

                          //this field will generate a toggle linked to the 'necessary' category
                          linkedCategory: 'necessary',
                          cookieTable: {
                            headers: {
                                name: 'Nom',
                                domain: 'Service',
                                description: 'Description',
                                expiration: 'Expiration'
                            },
                            body: [
                              {
                                name: 'st-hosted-token',
                                domain: 'ShareTribe',
                                description: 'Le cookie Sharetribe est uniquement utilisé pour les informations de session.',
                                expiration: '1 mois'
                            },

                            {
                              name: 'Langue',
                              domain: 'Hapiklan',
                              description: 'Enregistre la préférence de langue des utilisateurs',
                              expiration: 'Session'
                          },
                          {
                            name: 'hasSubscribed',
                            domain: 'Hapiklan',
                            description: 'Enregistrer la préférence de newsletter des utilisateurs',
                            expiration: 'Session'
                        },
                        {
                            name: 'lastModalClosedTime',
                            domain: 'Hapiklan',
                            description: 'Enregistrer l\'heure de fermeture du dernier modal des utilisateurs',
                            expiration: 'Session'
                        },
                        {
                          name: 'cc_cookie',
                          domain: 'Hapiklan',
                          description:
                            'Enregistrer la préférence des cookies des utilisateurs',
                          expiration: '7 mois',
                        },
                        {
                          name: '__stripe_mid',
                          domain: 'Stripe',
                          description: 'Défini à des fins de prévention de la fraude et nous aide à évaluer le risque associé à une tentative de transaction.',
                          expiration: '1 an',
                          },
                          {
                          name: '__stripe_sid',
                          domain: 'Stripe',
                          description: 'Défini à des fins de prévention de la fraude et nous aide à évaluer le risque associé à une tentative de transaction.',
                          expiration: '30 minutes',
                          }
                              
                            ],
                            
                          },
                      },
                      
                      {
                          title: 'Performance et Analytics',
                          // description: 'Ces cookies collectent des informations sur la manière dont vous utilisez notre site web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
                          linkedCategory: 'analytics',
                          cookieTable: {
                          caption: "Ces cookies collectent des informations sur la manière dont vous utilisez notre site Web. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.",
                          headers: {
                            name: "Nom",
                            domain: "Service",
                            description: "Description",
                            expiration: "Expiration"
                          },
                          body: [
                            {
                              name: "_ga",
                              domain: "Google Analytics",
                              description: "Cookie défini par Google Analytics.",
                              expiration: "2 ans"
                            },
                            {
                              name: "_fbp",
                              domain: "Meta Pixel",
                              description: "Cookie défini par Meta Pixel.",
                              expiration: "3 mois"
                            },
                            {
                              name: "_hjSession",
                              domain: "Hotjar",
                              description: "Cookie défini par Hotjar.",
                              expiration: "1 an"
                            }
    ]
  }
                      },
                      {
                          title: 'Plus d\'informations',
                          description: 'Pour toute question concernant notre politique sur les cookies et vos choix, Veuillez nous contacter'
                      }
                  ]
              }
          },



      
  
            
            
        },
        
  }
        
}
