import React, { useState, useEffect } from 'react';
import { injectIntl } from '../../util/reactIntl';
import css from './ChangeLanguageForm.module.css';
import { useDispatch } from 'react-redux';
import { updateProfileLanguage } from '../../ducks/user.duck';
import Select from 'react-select';
import { GoChevronDown } from 'react-icons/go';
import Popup from 'reactjs-popup';
const ChangeLanguageFormComponent = props => {
  const { intl, currentUser } = props;
  const [value, setValue] = useState('fr');
  const { LandingPage } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'fr';
    if (cookieValue == 'en-US') {
      setValue('en-US');
    } else {
      setValue('fr');
    }
  }, []);

  const handleOnChange = e => {
    const selectedOption = e.value;
    setValue(selectedOption);
    dispatch(updateProfileLanguage(selectedOption));
  };

  const englishLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.englishLanguage',
  });
  const frenchLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.frenchLanguage',
  });

  const data = [
    {
      value: 'en-US',
      text: englishLanguage,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#071b65"></rect>
          <path
            d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
            fill="#fff"
          ></path>
          <path
            d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
            fill="#b92932"
          ></path>
          <path
            d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
            fill="#b92932"
          ></path>
          <path
            d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
            fill="#fff"
          ></path>
          <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
          <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
          <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
          <rect
            x="14"
            y="1"
            width="4"
            height="30"
            transform="translate(32) rotate(90)"
            fill="#b92932"
          ></rect>
          <path
            d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
            fill="#b92932"
          ></path>
          <path
            d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
            fill="#b92932"
          ></path>
          <path
            d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
            opacity=".15"
          ></path>
          <path
            d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
            fill="#fff"
            opacity=".2"
          ></path>
        </svg>
      ),
    },
    {
      value: 'fr',
      text: frenchLanguage,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path fill="#fff" d="M10 4H22V28H10z"></path>
          <path d="M5,4h6V28H5c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z" fill="#092050"></path>
          <path
            d="M25,4h6V28h-6c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z"
            transform="rotate(180 26 16)"
            fill="#be2a2c"
          ></path>
          <path
            d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
            opacity=".15"
          ></path>
          <path
            d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
            fill="#fff"
            opacity=".2"
          ></path>
        </svg>
      ),
    },
  ];
  const languageOptionDesktop = (
    <div className={css.desktop}>
      <Popup
        key="aaaa"
        trigger={
          <div className={css.menuLabel}>
            <div className={css.changeLanguageForm}>
              {value == 'en-US' ? 'EN' : 'FR'}
              <GoChevronDown className={css.downIcon} />
            </div>
          </div>
        }
        position="bottom center"
        on={['hover', 'focus']}
      >
        {data?.map((item, index) => (
          <div
            key={index}
            className={css.menuItem}
            onClick={() => {
              handleOnChange(item);
            }}
          >
            <span> {item.icon}</span>
            <span>{item.text}</span>
          </div>
        ))}
      </Popup>
    </div>
  );
  const languageOptionMobile = (
    <div className={css.mobile}>
      <Select
        className={LandingPage ? css.normalSelectLandingPage : css.normalSelect}
        isSearchable={false}
        value={data.find(_ => _.value == value)}
        options={data}
        onChange={e => handleOnChange(e)}
        getOptionLabel={e => (
          <div style={{ display: 'flex', alignItems: 'center' }} className={css.dataContainer}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.text}</span>
          </div>
        )}
      />
    </div>
  );

  return (
    <div className={css.main}>
      {languageOptionDesktop}
      {languageOptionMobile}
    </div>
  );
};

const ChangeLanguageForm = injectIntl(ChangeLanguageFormComponent);

export default ChangeLanguageForm;
